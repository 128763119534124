import {
  ApplyOpenPageRuleContext,
  OpenPageData,
  PAGE_TYPES,
  WbuButtonElement,
} from './types';

const PREMIUM_PAGE_BASE_URL = 'https://www.wix.com/apps/upgrade';

export const applyWbuButtonRules = async (
  element: WbuButtonElement,
  openPageData: OpenPageData,
  context: ApplyOpenPageRuleContext,
) => {
  const { wixEditor, applicationInstance, dashboardRoutePaths } = context;

  switch (openPageData.pageType) {
    case PAGE_TYPES.LINK: {
      element.target = '_blank';
      element.link = openPageData.linkUrl;
      break;
    }
    case PAGE_TYPES.PREMIUM_PAGE: {
      if (!applicationInstance) {
        throw new Error(
          'ApplicationInstance can not be undefined for premium page',
        );
      }
      const { appDefId, instanceId } = applicationInstance;
      const upgradeURL = `${PREMIUM_PAGE_BASE_URL}/${appDefId}?appInstanceId=${instanceId}`;

      element.target = '_blank';
      element.link = upgradeURL;
      break;
    }
    case PAGE_TYPES.DASHBOARD: {
      if (!applicationInstance) {
        throw new Error(
          'ApplicationInstance can not be undefined for dashboard page',
        );
      }

      const { appDefId } = applicationInstance;
      const { devCenterCompId } = openPageData;

      const dashboardRoutePath = dashboardRoutePaths.find(
        (x) => x.devCenterComponentId === devCenterCompId,
      );

      if (dashboardRoutePath) {
        const { routePath } = dashboardRoutePath;

        element.onClick(async () => {
          await wixEditor.openDashboardPanel({
            url: routePath ? `${appDefId}/${routePath}` : appDefId,
          });
        });
      }

      break;
    }
    default:
      break;
  }
};
