import ExtendableError from 'es6-error';

export const ERROR_NAME = 'NotAllowedOriginError';

export class NotAllowedOriginError extends ExtendableError {
  url: string;

  constructor(url: string) {
    super('Tried loading script from non allowed origin');
    this.name = ERROR_NAME;
    this.url = url;
  }
}
