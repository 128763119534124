import { PanelControllerConfig } from './panel';
import { DashboardControllerConfig } from './dashboard';
import { PreviewWidgetControllerConfig } from './widget';
import { LowCodeControllerConfig } from './low-code';

export type PreviewControllerConfig =
  | PreviewWidgetControllerConfig
  | PanelControllerConfig
  | DashboardControllerConfig
  | LowCodeControllerConfig;

export function isDashboardControllerConfig(
  controllerConfig: PreviewControllerConfig,
): controllerConfig is DashboardControllerConfig {
  return controllerConfig.type === 'dashboard-controller';
}

export function isLowCodeControllerConfig(
  controllerConfig: PreviewControllerConfig,
): controllerConfig is LowCodeControllerConfig {
  return controllerConfig.type === 'low-code';
}

export function isPanelControllerConfig(
  controllerConfig: PreviewControllerConfig,
): controllerConfig is PanelControllerConfig {
  return Boolean(
    (controllerConfig as PanelControllerConfig).config.isPanelController,
  );
}
