import type { IWixEditor } from '@wix/blocks-velo-typings';
import { ApplicationNamespace } from '../../../../utils/applicationNamespaceCreator';

export const PAGE_TYPES = {
  DASHBOARD: 'dashboard',
  PREMIUM_PAGE: 'premiumPage',
  LINK: 'link',
} as const;

export type OpenPageData =
  | { pageType: typeof PAGE_TYPES.DASHBOARD; devCenterCompId: string }
  | { pageType: typeof PAGE_TYPES.PREMIUM_PAGE }
  | { pageType: typeof PAGE_TYPES.LINK; linkUrl: string };

export type WbuButtonElement = {
  type: '$w.WbuButton';
  connectionConfig?: {
    properties?: OpenPageData;
  };
  target: string;
  link: string;
  onClick: (callback: () => Promise<void>) => void;
};

export type Element = WbuButtonElement;

export type NsWithWixApplication = {
  'wix-application'?: ApplicationNamespace;
};

export type ApplyOpenPageRuleContext = {
  wixEditor: IWixEditor;
  applicationInstance: Record<string, any> | null;
  dashboardRoutePaths: DashboardRoutePath[];
};

export type DashboardRoutePath = {
  devCenterComponentId: string;
  routePath?: string;
};

export type ElementData = {
  element: Element;
  openPageData: OpenPageData;
};

export type ApplyOpenPageRule = (
  element: Element,
  openPageData: OpenPageData,
  context: ApplyOpenPageRuleContext,
) => Promise<void>;
