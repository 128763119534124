import { PanelControllerConfig } from '@wix/blocks-widget-services-types';
import { createPanelController } from './createPanelController';
import { getRemoteModuleParamsBase } from './getRemoteModuleParams';
import { RemoteModuleArgsBase } from './services/remoteModuleArgs';

export type PanelModuleArgs = RemoteModuleArgsBase;

export type PanelModule = (args: PanelModuleArgs) => Promise<{}>;

export async function createRemotePanelController(
  controllerConfig: PanelControllerConfig,
  panelModule: PanelModule,
) {
  return createPanelController(
    controllerConfig,
    async (panelControllerConfig: PanelControllerConfig) => {
      panelControllerConfig.appParams.instance =
        (await panelControllerConfig.wixCodeApi.editor?.getAppInstance()) ||
        panelControllerConfig.appParams.instance;

      const remoteModuleParams = await getRemoteModuleParamsBase(
        panelControllerConfig,
      );

      await panelModule(remoteModuleParams);

      /* istanbul ignore next */
      return {
        pageReady: () => {},
        exports: () => ({}),
      };
    },
  );
}
