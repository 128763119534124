import { LowCodeControllerConfig } from '@wix/blocks-widget-services-types';
import { LowCodeRulesContext } from '..';

type Element = any;

interface RuleConfig {
  propertyName: string;
  innerWidgetSelector?: string;
}

function parseRuleConfig(element: Element): RuleConfig {
  const connectionConfig = element?.connectionConfig;
  return {
    propertyName: connectionConfig?.properties?.propertyName,
    innerWidgetSelector: connectionConfig?.properties?.innerWidgetSelector,
  };
}

export function applyPanelToPropRules({
  controllerConfig,
}: LowCodeRulesContext) {
  const $w = controllerConfig.$w;

  return Promise.all(
    $w('@rule-setWidgetProps').map(async (element: Element) => {
      const bindElementToWidgetProp = getBinder(element);
      await bindElementToWidgetProp(controllerConfig, element);
    }),
  );
}

type PropBinder = (
  controllerConfig: LowCodeControllerConfig,
  sourceElement: Element,
) => Promise<void>;

const createPropBinder =
  (valueProperty: string): PropBinder =>
  async (controllerConfig, sourceElement) => {
    const ruleConfig = parseRuleConfig(sourceElement);
    const wixWidget = await getTargetWidget(controllerConfig, ruleConfig);

    sourceElement.onChange(async () => {
      await wixWidget.setProps({
        [ruleConfig.propertyName]: sourceElement[valueProperty],
      });
    });

    const props = await wixWidget.getProps();
    if (props && ruleConfig.propertyName in props) {
      sourceElement[valueProperty] = props[ruleConfig.propertyName];
    }
  };

function getBinder(element: Element): PropBinder {
  const binder = binderByCompType[element.type];
  if (!binder) {
    throw new Error(
      `LowCode prop binding for component with type "${element.type}" is not supported."`,
    );
  }
  return binder;
}

const valueToPropBinder = createPropBinder('value');
const binderByCompType: Record<string, PropBinder> = {
  '$w.WbuThumbnails': valueToPropBinder, // string
  '$w.WbuCheckboxGroup': valueToPropBinder, // string[]
  '$w.WbuDropdown': valueToPropBinder, // string
  '$w.WbuRadioButtonGroup': valueToPropBinder, // string
  '$w.WbuRichText': valueToPropBinder, // string
  '$w.WbuSlider': valueToPropBinder, // number
  '$w.WbuTextInput': valueToPropBinder, // string
  '$w.WbuToggleSwitch': createPropBinder('checked'), // boolean,
};

async function getTargetWidget(
  controllerConfig: LowCodeControllerConfig,
  ruleConfig: RuleConfig,
) {
  const wixWidget = controllerConfig.wixCodeApi.widget;

  if (!wixWidget) {
    throw new Error(
      "Can't bind widget prop. No wixWidget API provided for LowCode controller",
    );
  }

  if (ruleConfig.innerWidgetSelector) {
    return wixWidget.getNestedWidget(ruleConfig.innerWidgetSelector);
  }

  return wixWidget;
}
