import {
  $W,
  isDashboardControllerConfig,
  isLowCodeControllerConfig,
  PreviewControllerConfig,
} from '@wix/blocks-widget-services-types';
import constants from './constants';

const wrapFireEvent = ($w: $W) => {
  const originalFireEvent = $w.fireEvent;

  return (eventName: string, event: unknown) => {
    console.info(`Event fired: ${eventName}`);
    originalFireEvent(eventName, event);
  };
};

export const wrapControllerConfigsForPreviewApp = (
  controllersConfigs: PreviewControllerConfig[],
): PreviewControllerConfig[] =>
  controllersConfigs.map((controllerConfig) => {
    if (controllerConfig.wixCodeApi.window.viewMode !== 'Site') {
      controllerConfig.appParams.instance = constants.PREVIEW_INSTANCE;
    }

    controllerConfig.$w.fireEvent = wrapFireEvent(controllerConfig.$w);

    return controllerConfig;
  });

export const adjustConfigsForOverriddenScript = (
  controllersConfigs: PreviewControllerConfig[],
  overriddenAppId: string,
): PreviewControllerConfig[] =>
  controllersConfigs.map((controllerConfig) => {
    if (
      isDashboardControllerConfig(controllerConfig) ||
      isLowCodeControllerConfig(controllerConfig)
    ) {
      return controllerConfig;
    }

    return {
      ...controllerConfig,
      type: `${overriddenAppId}-${controllerConfig.type}`,
    };
  });
