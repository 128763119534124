import {
  AppParams,
  BlocksPreviewAppData,
  PreviewWidgetControllerConfig,
  WidgetMetaData,
} from '@wix/blocks-widget-services-types';
import { IMetaDataProvider } from './IMetaDataProvider';

const getEmptyWidgetMetaData = (): WidgetMetaData => ({
  functions: [],
  events: [],
  properties: [],
  behaviors: [],
});

export const getWidgetMetaData = (
  { appData }: AppParams<BlocksPreviewAppData>,
  widgetType: string,
): WidgetMetaData =>
  appData?.blocksPreviewData?.widgetDescriptorsMap[widgetType] ??
  getEmptyWidgetMetaData();

export class PreviewMetaDataProvider implements IMetaDataProvider {
  constructor(private appParams: PreviewWidgetControllerConfig['appParams']) {}

  getMetaData(widgetType: string) {
    return getWidgetMetaData(this.appParams, widgetType);
  }
}
