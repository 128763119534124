import {
  BlocksPreviewAppData,
  DashboardControllerConfig,
} from '@wix/blocks-widget-services-types';
import { IAppData } from '@wix/native-components-infra/dist/src/types/types';
import { generatePreviewWidgetCodeUrl } from '../utils/urlBuilder';
import { loadWidgetModule } from '../utils/importScriptAsAmdModule';
import {
  createRemoteDashboardController,
  DashboardModule,
} from '../createRemoteDashboardController';

export class RemoteDashboardLoader {
  constructor(private appParams: IAppData<BlocksPreviewAppData>) {}

  private async loadVeloDashboardCode(
    controllerConfig: DashboardControllerConfig,
  ) {
    const { config } = controllerConfig;

    const dashboardCodeUrl = generatePreviewWidgetCodeUrl(
      config.dashboardId,
      this.appParams,
    );

    const dashboardModule = loadWidgetModule<DashboardModule>(dashboardCodeUrl);

    return createRemoteDashboardController(controllerConfig, dashboardModule);
  }

  async loadDashboard(controllerConfig: DashboardControllerConfig) {
    return this.loadVeloDashboardCode(controllerConfig);
  }
}
