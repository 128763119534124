import { applyPanelToPropRules } from './ruleHandlers/widgetProp';
import { applyOpenPageRule } from './ruleHandlers/openPage/applyOpenPageRule';
import { LowCodeControllerConfig } from '@wix/blocks-widget-services-types';
import { RemoteModuleArgsBase } from '../../services/remoteModuleArgs';

export type LowCodeRulesContext = {
  controllerConfig: LowCodeControllerConfig;
  remoteModule: RemoteModuleArgsBase;
};

export async function applyLowCodeRules(
  lowCodeRulesContext: LowCodeRulesContext,
) {
  await Promise.all([
    applyPanelToPropRules(lowCodeRulesContext),
    applyOpenPageRule(lowCodeRulesContext),
  ]);
}
