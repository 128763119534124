import { loadWidgetModule } from '../utils/importScriptAsAmdModule';
import { IWidgetModuleProvider, WidgetModule } from './IWidgetModuleProvider';
import { generatePreviewWidgetCodeUrl } from '../utils/urlBuilder';
import {
  AppParams,
  BlocksPreviewAppData,
} from '@wix/blocks-widget-services-types';
import { NotBlocksPreviewAppError } from '../errors/notBlocksPreviewAppError';

const isBlocksPreviewAppParams = (
  appParams: AppParams,
): appParams is AppParams<BlocksPreviewAppData> =>
  'blocksPreviewData' in appParams.appData!;

export class PreviewWidgetModuleProvider implements IWidgetModuleProvider {
  private _widgetModuleStore: Record<string, WidgetModule<object>> = {};

  constructor(private appParams: AppParams) {}

  getModule<T extends object>(widgetType: string) {
    /* istanbul ignore next */
    if (!isBlocksPreviewAppParams(this.appParams)) {
      throw new NotBlocksPreviewAppError();
    }
    const widgetCodeUrl = generatePreviewWidgetCodeUrl(
      widgetType,
      this.appParams,
    );

    if (!this._widgetModuleStore[widgetCodeUrl]) {
      this._widgetModuleStore[widgetCodeUrl] =
        loadWidgetModule<WidgetModule<object>>(widgetCodeUrl);
    }

    return this._widgetModuleStore[widgetCodeUrl] as WidgetModule<T>;
  }
}
