import { DashboardControllerConfig } from '@wix/blocks-widget-services-types';
import { createDashboardController } from './createDashboardController';
import { getRemoteModuleParamsBase } from './getRemoteModuleParams';
import { RemoteModuleArgsBase } from './services/remoteModuleArgs';

export type DashboardModuleArgs = RemoteModuleArgsBase;

export type DashboardModule = (args: DashboardModuleArgs) => Promise<{}>;

export async function createRemoteDashboardController(
  controllerConfig: DashboardControllerConfig,
  dashboardModule: DashboardModule | undefined,
) {
  return createDashboardController(
    controllerConfig,
    async (dashboardControllerConfig: DashboardControllerConfig) => {
      controllerConfig.appParams.instance =
        controllerConfig.wixCodeApi.location.query?.instance ||
        controllerConfig.appParams.instance;

      const remoteModuleParams = await getRemoteModuleParamsBase(
        dashboardControllerConfig,
      );

      if (dashboardModule) {
        await dashboardModule(remoteModuleParams);
      }

      /* istanbul ignore next */
      return {
        pageReady: () => {},
        exports: () => ({}),
      };
    },
  );
}
