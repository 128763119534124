import { applyWbuButtonRules } from './wbuButton';
import {
  ApplyOpenPageRule,
  DashboardRoutePath,
  Element,
  ElementData,
  NsWithWixApplication,
  PAGE_TYPES,
} from './types';
import { LowCodeRulesContext } from '../..';
import { $W } from '@wix/blocks-widget-services-types';
import { EditorSDK } from '@wix/platform-editor-sdk';
import { BlocksAppData } from '@wix/document-services-types';

const buttonRules: Record<string, ApplyOpenPageRule> = {
  '$w.WbuButton': applyWbuButtonRules,
};

function getElementDataPairs($w: $W): ElementData[] {
  return $w('@rule-openPage')
    .map((element: Element) => {
      const openPageData = element?.connectionConfig?.properties;
      return openPageData ? { element, openPageData } : undefined;
    })
    .filter((elementData: ElementData) => !!elementData);
}

function requiresAppInstance(elementDataPairs: ElementData[]): boolean {
  return elementDataPairs.some(
    ({ openPageData }) =>
      openPageData.pageType === PAGE_TYPES.DASHBOARD ||
      openPageData.pageType === PAGE_TYPES.PREMIUM_PAGE,
  );
}

function requiresDashboardComponents(elementDataPairs: ElementData[]): boolean {
  return elementDataPairs.some(
    ({ openPageData }) => openPageData.pageType === PAGE_TYPES.DASHBOARD,
  );
}

async function fetchApplicationInstance(context: LowCodeRulesContext) {
  try {
    const { remoteModule } = context;
    const wixApplication = (remoteModule.$ns as NsWithWixApplication)[
      'wix-application'
    ];
    if (!wixApplication) {
      throw new Error(
        'wix-application could not be extracted from namespace module',
      );
    }
    return wixApplication.getDecodedAppInstance();
  } catch (error) {
    console.error('Failed to get application instance:', error);
    throw error;
  }
}

async function getDashboardRoutePaths(
  context: LowCodeRulesContext,
  applicationInstance: Record<string, any>,
): Promise<DashboardRoutePath[]> {
  const { editorSdk } =
    // @ts-expect-error
    (await context.controllerConfig.wixCodeApi?.mainFrameEditor?.getMainFrameEditorContext()) as {
      editorSdk: EditorSDK;
    };
  const { appDefId } = applicationInstance;

  const appData = (await editorSdk.tpa.app.getDataByAppDefId(
    'token',
    appDefId,
  )) as BlocksAppData;

  const dashboardPages = appData.components.filter(
    (x) => x.type === 'BACK_OFFICE_PAGE',
  );

  return dashboardPages.map((x) => ({
    devCenterComponentId: x.componentId,
    routePath: x.data.routePath,
  }));
}

async function getWixEditor(context: LowCodeRulesContext) {
  const { wixCodeApi } = context.controllerConfig;
  const wixEditor = wixCodeApi.editor;
  if (!wixEditor) {
    throw new Error('wix-editor could not be extracted from namespace module');
  }
  return wixEditor;
}

async function createApplyRulesContext(
  context: LowCodeRulesContext,
  elementDataPairs: ElementData[],
) {
  let applicationInstance: Record<string, any> | null = null;
  if (requiresAppInstance(elementDataPairs)) {
    applicationInstance = await fetchApplicationInstance(context);
  }

  let dashboardRoutePaths: DashboardRoutePath[] = [];
  if (requiresDashboardComponents(elementDataPairs) && applicationInstance) {
    dashboardRoutePaths = await getDashboardRoutePaths(
      context,
      applicationInstance,
    );
  }

  const wixEditor = await getWixEditor(context);

  return {
    wixEditor,
    applicationInstance,
    dashboardRoutePaths,
  };
}

export async function applyOpenPageRule(context: LowCodeRulesContext) {
  const { controllerConfig } = context;
  const { $w } = controllerConfig;

  const elementDataPairs: ElementData[] = getElementDataPairs($w);
  if (elementDataPairs.length === 0) {
    return;
  }

  const applyRulesContext = await createApplyRulesContext(
    context,
    elementDataPairs,
  );

  for (const { element, openPageData } of elementDataPairs) {
    const applyRules = buttonRules[element.type];
    if (applyRules) {
      await applyRules(element, openPageData, applyRulesContext);
    }
  }
}
