export const isLocalhost = (url: string): Boolean => {
  const parsedUrl = tryGetParsedUrl(url);
  if (!parsedUrl) {
    return false;
  }

  return (
    (parsedUrl.hostname === 'localhost' ||
      parsedUrl.hostname === '127.0.0.1') &&
    !!parsedUrl.port &&
    /\.js$/.test(parsedUrl.pathname)
  );
};

function tryGetParsedUrl(url: string) {
  try {
    return new URL(url);
  } catch (error) {
    return undefined;
  }
}

const isParastorageHost = (url: string): Boolean => {
  const parsedUrl = new URL(url);
  return (
    parsedUrl.hostname === 'static.parastorage.com' &&
    /\.js$/.test(parsedUrl.pathname)
  );
};

export const isLegalViewerScriptUrl = (url: string): Boolean => {
  return isLocalhost(url) || isParastorageHost(url);
};
