const CONSTANTS = {
  APP_DEF_ID: '46b2ad43-5720-41d2-8436-2058979cb53f',
  SENTRY: {
    DSN: 'https://3f44d0ab662b4ac3b73490d776fa78c4@sentry-next.wixpress.com/531',
  },
  INTERACTIONS: {
    PREFIX: 'blocks-preview-app_',
    LOAD_WIDGET: 'load-widget',
    INIT_APP_FOR_PAGE: 'init-app-for-page',
    LOAD_PANEL_CONTROLLER: 'load-panel-controller',
  },
  EXPERIMENTS_SCOPE: 'blocks-preview-client',
  WIX_CODE_APP_DEF_ID: '675bbcef-18d8-41f5-800e-131ec9e08762',
  PREVIEW_INSTANCE: 'preview-instance',
} as const;

export default CONSTANTS;
