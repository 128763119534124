import ExtendableError from 'es6-error';

const ERROR_NAME = 'NotBlocksPreviewAppError';

export class NotBlocksPreviewAppError extends ExtendableError {
  constructor() {
    super('Not a blocks preview app - Missing blocksPreviewData in app params');
    this.name = ERROR_NAME;
  }
}
