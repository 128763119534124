import {
  ControllerWithExportsFunction,
  LowCodeControllerConfig,
} from '@wix/blocks-widget-services-types';
import { applyLowCodeRules } from './panel';
import { getRemoteModuleParamsBase } from '../getRemoteModuleParams';

export async function createLowCodeController(
  controllerConfig: LowCodeControllerConfig,
): Promise<ControllerWithExportsFunction<Record<string, any>>> {
  controllerConfig.appParams.instance =
    (await controllerConfig.wixCodeApi.editor?.getAppInstance()) ||
    controllerConfig.appParams.instance;

  const remoteModule = await getRemoteModuleParamsBase(controllerConfig);

  return {
    pageReady: async () => {
      await applyLowCodeRules({ controllerConfig, remoteModule });
    },
    exports: () => ({}),
  };
}
