import { PlatformViewerApp } from '../types/types';

interface ExtendedScope extends WorkerGlobalScope {
  module?: { exports?: unknown };
  exports?: unknown;
  require: (mod: string) => unknown;
  [mod: string]: unknown;
}

function prepareScope(scope: unknown): asserts scope is ExtendedScope {
  const _scope = scope as ExtendedScope;

  _scope.module = {};
  _scope.module.exports = {};
  _scope.exports = _scope.module.exports;
}

function importScriptsAsNpmModule(scope: WorkerGlobalScope, url: string) {
  prepareScope(scope);
  const module = scope.module;
  const oldRequire = scope.require;
  /* istanbul ignore next */
  scope.require = function require(mod: string) {
    // enable use of require('lodash') for external bundles
    if (mod === 'lodash') {
      mod = '_';
    }

    if (!scope[mod]) {
      console.error(`Failed to require module: ${mod} from url: ${url}`);
    }

    return scope[mod];
  };

  try {
    scope.importScripts(url);
  } catch (err) {
    /* istanbul ignore next */
    console.error(`Failed to import script:, url: ${url}`, err);
  }

  delete scope.module;
  delete scope.exports;
  scope.require = oldRequire;

  return module?.exports;
}

export default function load(scriptUrl: string): PlatformViewerApp {
  return importScriptsAsNpmModule(self, scriptUrl) as PlatformViewerApp;
}
