import {
  WixCodeAPI,
  AppParams,
  BlocksPreviewAppData,
  PreviewControllerConfig,
} from '@wix/blocks-widget-services-types';
import { AppOverride } from '../types/types';
import { NotAllowedOriginError } from '../errors/notAllowedOriginError';
import { isLegalViewerScriptUrl } from '@wix/blocks-widget-services';

// TODO: find a more reliable way to know this
export const getRootControllerConfig = (
  controllersConfigs: PreviewControllerConfig[],
) => controllersConfigs[0];

const getViewerPlatformOverriddenDataFromUrl = (
  wixCodeAPI: WixCodeAPI,
): AppOverride | undefined => {
  const viewerPlatformOverrides: string =
    wixCodeAPI.location.query.viewerPlatformOverrides;

  if (!viewerPlatformOverrides) {
    return;
  }

  const [appDefId, viewerScriptUrl] = viewerPlatformOverrides.split('=');

  return {
    appDefId,
    viewerScriptUrl,
  };
};

const getBobViewerScriptOverriddenData = (
  wixCodeAPI: WixCodeAPI,
  appParams: AppParams<BlocksPreviewAppData>,
): AppOverride | undefined => {
  const bobViewerScriptOverride =
    wixCodeAPI.location.query.bobViewerScriptOverride;

  if (!bobViewerScriptOverride) {
    return;
  }

  const appDefId = appParams.appData?.blocksPreviewData.bobAppDefId;
  const viewerScriptUrl =
    bobViewerScriptOverride === 'true'
      ? appParams.appData?.blocksPreviewData.bobViewerScriptGA
      : bobViewerScriptOverride;

  if (!appDefId || !viewerScriptUrl) {
    return;
  }

  return {
    appDefId,
    viewerScriptUrl,
  };
};

export const getAppOverride = (
  wixCodeAPI: WixCodeAPI,
  appParams: AppParams<BlocksPreviewAppData>,
): AppOverride | undefined => {
  const viewerPlatformAppOverride =
    getViewerPlatformOverriddenDataFromUrl(wixCodeAPI);

  const bobViewerScriptOverride = getBobViewerScriptOverriddenData(
    wixCodeAPI,
    appParams,
  );

  const appOverride = bobViewerScriptOverride || viewerPlatformAppOverride;
  if (appOverride && !isLegalViewerScriptUrl(appOverride.viewerScriptUrl)) {
    throw new NotAllowedOriginError(appOverride.viewerScriptUrl);
  }

  return appOverride;
};
