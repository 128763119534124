import ExtendableError from 'es6-error';

const ERROR_NAME = 'NoWidgetCodeUrlError';

export class NoWidgetCodeUrlError extends ExtendableError {
  constructor(widgetType: string) {
    super(
      `No widget code url provided for widget ${widgetType} in widgetsCodeMap`,
    );
    this.name = ERROR_NAME;
  }
}
