import { CustomError } from './customError';
import { isError } from './isErrorLike';

export const ERROR_NAME = 'DashboardCreationError';

export class DashboardCreationError extends CustomError {
  originalMessage?: string;

  constructor(originalError: unknown) {
    super(
      ERROR_NAME,
      'Failed running create dashboard function',
      originalError,
    );
    if (isError(originalError)) {
      this.originalMessage = originalError.message;
    }
  }
}
