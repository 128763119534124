import { ControllerConfig } from '@wix/blocks-widget-services-types';
import { CustomError } from '../errors/customError';

export const doesFileExists = (
  httpClient: ControllerConfig<{}, {}>['essentials']['httpClient'],
  url: string,
): Promise<boolean> => {
  return httpClient
    .head(url)
    .then(() => true)
    .catch((reason) => {
      const allowedErrorCodes = [404, 403];
      if (
        reason.response &&
        allowedErrorCodes.includes(reason.response.status)
      ) {
        return false;
      }
      throw new CustomError(
        'PanelBobNetworkError',
        'Network error trying to access panel BoB code',
        reason,
      );
    });
};
