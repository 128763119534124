import { CustomError } from './customError';
import { isError } from './isErrorLike';

export const ERROR_NAME = 'PanelCreationError';

export class PanelCreationError extends CustomError {
  originalMessage?: string;

  constructor(originalError: unknown) {
    super(ERROR_NAME, 'Failed running create panel function', originalError);
    if (isError(originalError)) {
      this.originalMessage = originalError.message;
    }
  }
}
