import {
  ControllerWithExportsFunction,
  DashboardControllerConfig,
} from '@wix/blocks-widget-services-types';
import { DashboardCreationError } from './errors/dashboardCreationError';

export type CreateDashboardFn = (
  controllerConfig: DashboardControllerConfig,
) => Promise<ControllerWithExportsFunction<{}>>;

export async function createDashboardController(
  controllerConfig: DashboardControllerConfig,
  createDashboardFn: CreateDashboardFn,
): Promise<ControllerWithExportsFunction<{}>> {
  try {
    const dashboardController = await createDashboardFn(controllerConfig);

    /* istanbul ignore next */
    return {
      pageReady: (scoped$w) => {
        return dashboardController.pageReady(scoped$w);
      },
      exports: () => ({}),
    };
  } catch (e) {
    throw new DashboardCreationError(e);
  }
}
